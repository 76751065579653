<template>
  <div class="productFirst">
    <div>
      <img class="pc" src="../../../static/product/ldj.jpg" alt="">
      <img class="pc" src="../../../static/product/ldy.jpg" alt="">
      <img class="wap" src="../../../static/product/wapLdj.jpg" alt="">
      <img class="wap" src="../../../static/product/wapLdy.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name:'productFirst',
}
</script>

<style lang="less" scoped>
  .productFirst{
    width: 100%;
    div{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 15px 0 70px;
      img{
        width: 1200px;
        height: auto;
        margin-bottom: 50px;
      }
      .wap{
        display: none;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .productFirst{
      width: 100%;
      div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 0 30px;
        img{
          width: 100%;
          height: auto;
          margin-bottom: 30px;
        }
        .pc{
          display: none;
        }
        .wap{
          display: block;
          width: 97%;
          margin: 0 auto 20px;
        }
      }
    }
  }
</style>